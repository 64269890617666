/** @jsx jsx */
import { jsx } from 'theme-ui'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { Box, Flex } from 'rebass'
import { kebabCase, uniqueId } from 'lodash'

import { Container, Layout, Section } from '@giraldomac/gatsby-theme-mmdbase/src/components/layout'
import { SEO } from '@giraldomac/gatsby-theme-mmdbase/src/components/elements'
import { PubHero, CallToAction } from '../../components/elements'

const Departments = ({ data }) => {

  const page = data.prismic.departments

  return (
    <Layout>
      <SEO meta_title={page.meta_title} meta_description={page.meta_description} />
      <PubHero title={RichText.asText(page.title)} />

      <Section>
        <Container>
          <Flex flexWrap="wrap" justifyContent="center">
            <Box width={['full', 4 / 5, 3 / 4]} sx={{
              backgroundColor: 'wrapperbox',
              padding: [4, 5],
            }}>
              {RichText.render(page.content)}
              <hr />
              {page.body.map(({ primary }) => (
                <Box key={`${uniqueId('dept_id_')}`} id={`${kebabCase(RichText.asText(primary.title1))}`}>
                  {RichText.render(primary.title1)}
                  {RichText.render(primary.text)}
                </Box>
              ))}
            </Box>
          </Flex>
        </Container>
      </Section>

      <CallToAction />
    </Layout>
  )
}

export default Departments

export const query = graphql`
query DepartmentsQuery {
  prismic {
      departments(lang: "en-us", uid: "departments") {
        title
        content
        meta_title
        meta_description
        _linkType
        body {
          ... on PRISMIC_DepartmentsBodyText {
            label
            primary {
              text
              title1
            }
          }
        }
      }
    }
}
`